<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="categories !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-button
                  variant="primary"
                  @click="addNewCategory = !addNewCategory"
              >
                <span class="text-nowrap">+ Create new category</span>
              </b-button>
            </b-col>
            <!--   new Category name  -->
            <transition name="fade">
              <b-col
                  v-if="addNewCategory"
                  cols="12"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
              >
                <b-col md="6" class="px-0">
                  <div class="form-label-group">
                    <b-form-input
                        id="title"
                        placeholder="title"
                        v-model="defaultCreateData.name"
                    />
                  </div>
                </b-col>
                <b-col md="2" class="mb-1">
                  <b-button
                      @click="createProductCategory"
                      variant="success"
                  >
                    <span class="text-nowrap">Create</span>
                  </b-button>
                </b-col>
              </b-col>
            </transition>
          </b-row>
        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="categories"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="Nothing found !"
        >

          <!-- Column: delete -->

          <template #cell(delete)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-delete
                 @click="showModal(data.item.blogCategoryId)"
            >
              <feather-icon size="20" icon="TrashIcon" class="text-danger" />
            </div>

          </template>

          <template #cell(edit)="data">

            <router-link :to="{ name: 'pages-blog-categories-edit', params: { id: data.item.blogCategoryId } }" class="cursor-pointer d-flex flex-row">
              <feather-icon size="20" icon="EditIcon" />
            </router-link>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="delete"
        cancelTitle="cancel"
        @ok="deleteCategory(deleteItem)"
    >
      <span>Are you sure?</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BOverlay,BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {BlogCategoryGetAllRequest} from "@/libs/Api/Blog";
import {BlogCategoryCreateRequest} from "@/libs/Api/Blog";
import {BlogCategoryDeleteRequest} from "@/libs/Api/Blog";

export default {
  name: "categories-list",
  title:"Blog Categories List",
  data(){
    return{
      categories:null,
      overlay:false,
      totalCount:null,
      addNewCategory:false,
      deleteItem:null,
      currentPage:1,
      perPage:10,
      myTableColumns : [
        { key: 'blogCategoryId',label:'id'},
        { key: 'name',label:'title'},
        { key: 'delete',label:'delete'},
        { key: 'edit',label:'edit'},
      ],
      defaultCreateData:{
        isDeleted:false,
        blogCategoryId:0,
        name:'',
        description:'asd'
      },
    }
  },
  async created(){
    await this.getBlogCategories();
  },
  methods:{
    async getBlogCategories(){
      let _this = this;
      _this.overlay = true;

      let blogCategoryGetAllRequest = new BlogCategoryGetAllRequest(_this);
      await blogCategoryGetAllRequest.fetch(function (content){
        _this.overlay = false;
        _this.categories = content
      } , function (content){
        _this.overlay = false;
        console.log(content)
      });
    },
    async createProductCategory(){
      let _this = this;
      _this.overlay = true;

      let blogCategoryCreateRequest = new BlogCategoryCreateRequest(_this);
      blogCategoryCreateRequest.setParams(_this.defaultCreateData);
      await blogCategoryCreateRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Done`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `Item added.`,
          },
        })
        _this.getBlogCategories();
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async deleteCategory(param){
      let _this = this;
      _this.overlay = true;

      let blogCategoryDeleteRequest = new BlogCategoryDeleteRequest(_this);
      blogCategoryDeleteRequest.setId(param);
      await blogCategoryDeleteRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Done`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `Item deleted`,
          },
        })
        _this.getBlogCategories()
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    showModal(param){
      let _this = this;
      _this.deleteItem = param
    },
  },
  components:{
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style scoped>

</style>